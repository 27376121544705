import { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import "../../Styles/frameworks.scss";
import Input from "../Util/Input"; // Assuming this is your custom Input component
import { Select, Button, Box, Typography, Grid } from "@mui/material"; // Import MUI components
import VCStats from "../ValueChains/MonitoringStats"; // Ensure this component exists
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pagination from "../Util/Pagination"; // Ensure this component exists
import WaveLoading from "../Util/WaveLoading"; // Ensure this component exists
import Results from "./Results";
import Competencies from "./Competencies";

export default function Framework(props) {
  const [data, setData] = useState({});
  const [charts, setCharts] = useState(null);
  const [cCharts, setCcharts] = useState(null);
  const [showing, setShowing] = useState(true);
  const [year, setYear] = useState(new Date().getFullYear().toString());
  const [selected, setSelected] = useState("Summary");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [activeF, setActiveF] = useState(year);

  const jwt = require("jsonwebtoken");

  const token = localStorage.getItem("gdfhgfhtkngdfhgfhtkn");
  const decoded = jwt.decode(token);
  const partner = decoded.Partner;

  const pathname = window.location.pathname.split("/");
  const title = pathname[1];

  useEffect(() => {
    const today = new Date();
    setStart(`${today.getFullYear() - 1}-04-01`);
    setEnd(`${today.getFullYear() + 1}-03-31`);
    if (start !== "" && end !== "") {
      // getData();
    }
  }, [refresh]);

  const Bar = (params) => {
    return (
      <Box
        onClick={() => {
          setSelected(params.txt);
        }}
        className={selected === params.txt ? "active" : "bar"}
        sx={{
          cursor: "pointer",
          padding: 1,
          border: "1px solid",
          borderColor: selected === params.txt ? "primary.main" : "grey.300",
          borderRadius: 1,
        }}
      >
        <Typography>{params.txt}</Typography>
      </Box>
    );
  };

  const exportToExcel = (filename, data) => {
    // Same export logic as before...
  };

  useEffect(() => {
    const nextYear = Number(year) + 1;
    switch (activeF) {
      case "Q1":
        setStart(`${year}-04-01`);
        setEnd(`${year}-06-30`);
        break;
      case "Q2":
        setStart(`${year}-07-01`);
        setEnd(`${year}-09-30`);
        break;
      case "Q3":
        setStart(`${year}-10-01`);
        setEnd(`${year}-12-31`);
        break;
      case "Q4":
        setStart(`${nextYear}-01-01`);
        setEnd(`${nextYear}-03-31`);
        break;
      case year:
        setStart(`${year}-04-01`);
        setEnd(`${nextYear}-03-31`);
        break;
      default:
        break;
    }
  }, [activeF, year]);

  // Function to generate year range
  const generateYearRange = () => {
    const currentYear = new Date().getFullYear();
    const myyears = [];
    for (let i = currentYear - 3; i <= currentYear + 3; i++) {
      myyears.push(String(i));
    }
    return myyears;
  };

  return (
    <>
      {showing && (
        <Box className="stats" p={2}>
          <Box className="welcome" mb={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Box display="flex" gap={1}>
                  <Bar txt="Summary" />
                  <Bar txt="Results" />
                  <Bar txt="Competencies" />
                  <Bar txt="Assessments" />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Select
                  value={year}
                  onChange={(e) => {
                    setYear(e.target.value);
                    setActiveF(e.target.value);
                  }}
                  sx={{ minWidth: 120 }}
                >
                  {generateYearRange().map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </Select>
              </Grid>
            </Grid>
            <Box className="filter" display="flex" gap={1} mt={2}>
              {["Q1", "Q2", "Q3", "Q4"].map((q) => (
                <Button
                  key={q}
                  variant={activeF === q ? "contained" : "outlined"}
                  onClick={() => setActiveF(q)}
                >
                  {q}
                </Button>
              ))}
              <Button
                variant={activeF === year ? "contained" : "outlined"}
                onClick={() => setActiveF(year)}
              >
                {year}
              </Button>
              <Input
                handleChange={(e) => {
                  setStart(e.target.value);
                }}
                type="date"
                value={start}
                label="Start Date"
              />
              <Input
                handleChange={(e) => {
                  setEnd(e.target.value);
                }}
                type="date"
                value={end}
                label="End Date"
              />
            </Box>
          </Box>

          {selected === "Summary" && (
            <Top
              data={data}
              partner={partner}
              title={title}
              start={start}
              end={end}
            />
          )}
          {selected === "Results" && (
            <Results
              data={data}
              charts={charts}
              cCharts={cCharts}
              partner={partner}
              title={title}
              exportToExcel={exportToExcel}
              start={start}
              end={end}
            />
          )}
          {selected === "Competencies" && (
            <Competencies
              data={data}
              charts={charts}
              cCharts={cCharts}
              partner={partner}
              title={title}
              exportToExcel={exportToExcel}
              start={start}
              end={end}
            />
          )}
          {selected === "Assessments" && (
            <Assessments
              data={data}
              charts={charts}
              cCharts={cCharts}
              partner={partner}
              title={title}
              exportToExcel={exportToExcel}
              start={start}
              end={end}
            />
          )}
        </Box>
      )}
    </>
  );
}

const Top = (props) => {
  return (
    <Box
      className="vdata"
      p={2}
      bgcolor="background.paper"
      borderRadius={1}
      boxShadow={1}
    >
      <VCStats
        title={props.title}
        category={props.title}
        setRefresh={props.setRefresh}
        refresh={props.refresh}
        setValue={props.setValue}
        value={props.value}
        partner={props.partner}
        start={props.start}
        end={props.end}
      />
    </Box>
  );
};

const Assessments = (props) => {
  const p1ref = useRef();
  const [data, setData] = useState(null);
  const [offset, setOffset] = useState(0);
  const [count, setCount] = useState(0);
  const [paginatedMap, setPaginatedMap] = useState(true);
  const [isloading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [mapData, setMapData] = useState(null);
  const [head, setHead] = useState(null);
  const [filter, setFilter] = useState(null);
  const [filtered, setFiltered] = useState(null);
  const [downloadRaw, setDownloadRaw] = useState(null);
  const [showing, setShowing] = useState(null);

  const [fcolumn, setFColumn] = useState(null);
  const [foperator, setFOperator] = useState(null);
  const [fvalue, setFValue] = useState(null);

  let tool;

  if (props.title === "AMF") {
    tool = "Agrovets";
  } else if (props.title === "AHMF") {
    tool = "Practitioners";
  } else if (props.title === "FMF") {
    tool = "Farriers";
  }

  // Replace "%20" and "&%20" in the final title
  tool = tool.replace("%20", " ").replace("&%20", " & ");

  useEffect(() => {
    if (!fcolumn || !fvalue || !foperator) {
      setIsLoading(true);
      fetch(
        `/api/${tool.toLowerCase()}/monitoring/all/${props.partner}/${
          props.start
        }/${props.end}/${offset}`
      )
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setData(data);
          setIsLoading(false);
          const cols = Object.keys(data.data[0]);
          let d = [];
          cols.forEach((item) => {
            if (
              item !== "ObjectID" &&
              item !== "ID" &&
              item !== "Longitude" &&
              item !== "Latitude"
            ) {
              d.push(item);
            }
          });
          if (isMobile) {
            setCount(2);
          } else {
            let c = d.length > 5 ? 5 : d.length;
            setCount(c);
          }
          setHead(d);
          if (data?.data?.length > 0) {
            setData(data);
            setFiltered(data);
            paginatedMap && setMapData(data);
          }
        })
        .catch((e) => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(true);
      fetch(
        `/api/${tool.toLowerCase()}/monitoring/all/${
          props.partner
        }/${fcolumn}/${foperator}/${fvalue}/${offset}`
      )
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setIsLoading(false);
          const cols = Object.keys(data.data[0]);
          let d = [];
          cols.forEach((item) => {
            if (
              item !== "ObjectID" &&
              item !== "ID" &&
              item !== "Longitude" &&
              item !== "Latitude"
            ) {
              d.push(item);
            }
          });
          if (isMobile) {
            setCount(2);
          } else {
            let c = d.length > 5 ? 5 : d.length;
            setCount(c);
          }
          setHead(d);
          if (data?.data?.length > 0) {
            setData(data);
            setFiltered(data);
            paginatedMap && setMapData(data);
          }
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }
  }, [
    offset,
    refresh,
    paginatedMap,
    fvalue,
    foperator,
    fcolumn,
    props.start,
    props.end,
  ]);

  useEffect(() => {
    setIsLoading(true);
    fetch(
      `/api/${tool.toLowerCase()}/monitoring/data/all/${props.partner}/${
        props.start
      }/${props.end}`
    )
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setIsLoading(false);
        setDownloadRaw(data);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  }, [props.start, props.end]);

  return (
    <div className="vdata">
      <div className="titles">
        <h3>Assessment Progress</h3>
        <FontAwesomeIcon
          onClick={() => {
            props.exportToExcel(
              `${props.title} Assesment Data`,
              downloadRaw.data
            );
          }}
          className="download"
          icon={faDownload}
        >
          <span className="download-text">Download Data</span>
        </FontAwesomeIcon>
        <div className="filter">
          <div
            onClick={() => {
              if (!filter) {
                setFilter(true);
                setFColumn("Country");
                setFOperator("ILIKE");
              } else {
                setFilter(false);
                setFColumn(null);
                setFOperator(null);
                setFValue(null);
              }
            }}
            className="add"
          >
            <i className="fa fa-plus-circle"></i>
            <p>{filter ? "Close Filter" : "Add Filter"}</p>
          </div>
          {filter && (
            <div className="math">
              <select
                onChange={(e) => {
                  setFColumn(e.target.value);
                }}
                name=""
                id=""
              >
                <option value="Country">Country</option>
                <option value="Name">Agrovet Name</option>
                <option value="Partner">Partner</option>
              </select>
              <select
                onChange={(e) => {
                  setFOperator(e.target.value);
                }}
                name=""
                id=""
              >
                <option value="ILIKE">ILIKE</option>
              </select>
              <input
                onChange={(e) => {
                  setFValue(e.target.value);
                }}
                type="text"
                name=""
                id=""
                placeholder="Value"
                required
              />
              <button
                onClick={() => {
                  setFilter(false);
                }}
              >
                Add
              </button>

              {props.filtered && (
                <FontAwesomeIcon
                  onClick={() => {
                    props.exportToExcel(
                      `${props.title} Filtered Data`,
                      filtered.data
                    );
                  }}
                  className="download"
                  icon={faDownload}
                />
              )}
            </div>
          )}
          {fcolumn && foperator && fvalue && (
            <div className="filter">
              <p>{fcolumn}</p>
              <h6>{foperator}</h6>
              <p>{fvalue}</p>
              <i
                onClick={() => {
                  setFColumn(null);
                  setFOperator(null);
                  setFValue(null);
                }}
                className="fa fa-times"
              ></i>
            </div>
          )}
        </div>
      </div>
      <hr />
      <div className="list">
        <div
          style={{
            gridTemplateColumns: `repeat(${head ? count : 0},1fr)`,
          }}
          className="head"
        >
          {head &&
            head.length > 0 &&
            head.map((item, i) => {
              if (i < count) {
                return <h4 key={i}>{item}</h4>;
              }
            })}
        </div>
        {data &&
          data?.data?.length > 0 &&
          data?.data?.map((item, i) => {
            return (
              <Item
                setShowing={setShowing}
                key={i}
                index={i}
                data={item}
                head={head}
                count={count}
              />
            );
          })}
        {props.isloading && <WaveLoading />}
        <div className="btns">
          {data?.total && (
            <Pagination
              total={data?.total}
              setOffset={setOffset}
              page={offset}
            />
          )}
        </div>
        {showing !== null && props.title === "AMF" && (
          <AMFPopup
            showing={showing}
            editing={props.editing}
            setEditing={props.setEditing}
            setShowing={setShowing}
            data={data?.data}
            url={props.url}
          />
        )}
        {showing !== null && props.title === "FMF" && (
          <Popup
            showing={showing}
            editing={props.editing}
            setEditing={props.setEditing}
            setShowing={setShowing}
            data={data?.data}
            url={props.url}
          />
        )}
        {showing !== null && props.title === "AHMF" && (
          <Popup
            showing={showing}
            editing={props.editing}
            setEditing={props.setEditing}
            setShowing={setShowing}
            data={data?.data}
            url={props.url}
          />
        )}
      </div>
    </div>
  );
};

const Item = (props) => {
  const cl = props.index % 2 == 0 ? "white" : "#60606010";

  return (
    <div
      style={{
        gridTemplateColumns: `repeat(${props.head ? props.count : 0},1fr)`,
        backgroundColor: cl,
      }}
      className="row"
      onClick={() => {
        props.setShowing(props.index);
      }}
    >
      {props.head.map((item, i) => {
        if (i < props.count) {
          return <p key={i}>{props.data[item]}</p>;
        }
      })}
    </div>
  );
};

const Popup = (props) => {
  const [isLoading, setIsLoading] = useState(null);
  const [cols, setCols] = useState(null);
  const [cls, setCls] = useState(null);
  const [data, setData] = useState(null);
  const [index, setIndex] = useState(0);
  const [active, setActive] = useState("Basic");

  useEffect(() => {
    setIsLoading(true);
    setCols(null);
    setCls(null);
    setData(null);
    if (active === "Basic") {
      if (props.showing !== null) {
        const cols = Object.keys(props.data[props.showing]);
        let d = [];
        cols.forEach((item) => {
          if (item.toLowerCase() !== "geom") {
            d.push(item);
          }
        });
        setCols(d);
      }
      setIsLoading(false);
    } else {
      let d = "farmeraddress";
      switch (active) {
        case "Address":
          d = "farmeraddress";
          break;
        case "Farm":
          d = "farmerresources";
          break;
        case "Groups":
          d = "farmergroups";
          break;
        case "Value Chains":
          d = "farmervaluechains";
          break;
        default:
          setActive("Basic");
          break;
      }
      fetch(`/api/${d}/${props.data[props.showing].NationalID}`)
        .then((res) => {
          if (res.ok) return res.json();
          else throw Error("");
        })
        .then((data) => {
          setIsLoading(false);
          if (data.length > 0) {
            setData(data);
            const cols = Object.keys(data[0]);
            let d = [];
            cols.forEach((item) => {
              if (item.toLowerCase() !== "geom") {
                d.push(item);
              }
            });
            setCls(d);
          }
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }
  }, [active]);

  const Bar = (params) => {
    return (
      <p
        onClick={() => {
          setActive(params.txt);
        }}
        className={active === params.txt ? "active" : ""}
      >
        {params.txt}
      </p>
    );
  };

  return (
    <div data-aos="fade-left" className="popup">
      <div className="container">
        <div className="dets">
          <h3>{props?.data[props.showing]?.Name}</h3>
          <i
            onClick={() => {
              props.setShowing(null);
            }}
            className="fa fa-times"
          ></i>
        </div>
        <div className="bar">
          <Bar txt="Details" />
        </div>
        <div className="content">
          {cols &&
            cols.map((item, i) => {
              return (
                <p key={i}>
                  <b>{item}</b> {props.data[props.showing][item]}
                </p>
              );
            })}
          {cls &&
            cls.map((item, i) => {
              return (
                <p key={i}>
                  <b>{item}</b> {data[index][item]}
                </p>
              );
            })}
          {isLoading && <WaveLoading />}
          <div className="tally">
            {data &&
              data.length > 1 &&
              data.map((item, i) => {
                return (
                  <p
                    className={i === index ? "active" : ""}
                    onClick={() => {
                      setIndex(i);
                    }}
                    key={i}
                  >
                    {i + 1}
                  </p>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

const AMFPopup = (props) => {
  const [isLoading, setIsLoading] = useState(null);
  const [cols, setCols] = useState(null);
  const [cls, setCls] = useState(null);
  const [data, setData] = useState(null);
  const [index, setIndex] = useState(0);
  const [active, setActive] = useState("Basic Details");

  useEffect(() => {
    setIsLoading(true);
    setCols(null);
    setCls(null);
    setData(null);

    if (props.showing !== null) {
      const cols = Object.keys(props.data[props.showing]);
      let d = [];
      cols.forEach((item) => {
        if (item.toLowerCase() !== "geom") {
          d.push(item);
        }
      });
      setCols(d);
    }
    setIsLoading(false);
  }, [active]);

  const Bar = (params) => {
    return (
      <p
        onClick={() => {
          setActive(params.txt);
        }}
        className={active === params.txt ? "active" : ""}
      >
        {params.txt}
      </p>
    );
  };

  return (
    <div data-aos="fade-left" className="popup">
      <div className="container">
        <div className="dets-popup">
          <h3>{props?.data[props.showing]?.Name}</h3>
          <h5>Quarter: {props?.data[props.showing]?.Quater}</h5>
          <h5>Assessor: {props?.data[props.showing]?.Assessor}</h5>
          <i
            onClick={() => {
              props.setShowing(null);
            }}
            className="fa fa-times"
          ></i>
        </div>
        <div className="bar">
          <Bar txt="Basic Details" />
          <Bar txt="Legal Compliance" />
          <Bar txt="Communication" />
          <Bar txt="Pharmacological Expert" />
          <Bar txt="Agrovet Content" />
          <Bar txt="Governance" />
        </div>
        <div className="content">
          {isLoading && <WaveLoading />}
          {active === "Basic Details" ? (
            <>
              <p>
                <b>Name:</b> {props.data[props.showing]["Name"]}
              </p>
              <p>
                <b>Country:</b> {props.data[props.showing]["Country"]}
              </p>
              <p>
                <b>County:</b> {props.data[props.showing]["County"]}
              </p>
              <p>
                <b>Town:</b> {props.data[props.showing]["Town"]}
              </p>
              <p>
                <b>Unit:</b> {props.data[props.showing]["Unit"]}
              </p>
              <p>
                <b>Location:</b> {props.data[props.showing]["Location"]}
              </p>
              <p>
                <b>Date:</b> {props.data[props.showing]["Date"]}
              </p>
              <p>
                <b>Quater:</b> {props.data[props.showing]["Quater"]}
              </p>
              <p>
                <b>Project:</b> {props.data[props.showing]["Project"]}
              </p>
              <p>
                <b>Partner:</b> {props.data[props.showing]["Partner"]}
              </p>
              <p>
                <b>Education:</b> {props.data[props.showing]["Education"]}
              </p>
              <p>
                <b>Profession:</b> {props.data[props.showing]["Profession"]}
              </p>
            </>
          ) : active === "Legal Compliance" ? (
            <>
              <p>
                <b>Registration:</b> {props.data[props.showing]["Registration"]}
              </p>
              <p>
                <b>Premises:</b> {props.data[props.showing]["Premises"]}
              </p>
              <p>
                <b>Pest:</b> {props.data[props.showing]["Pest"]}
              </p>
              <p>
                <b>Labelling:</b> {props.data[props.showing]["Labelling"]}
              </p>
            </>
          ) : active === "Communication" ? (
            <>
              <p>
                <b>Dispense:</b> {props.data[props.showing]["Dispense"]}
              </p>
              <p>
                <b>Vital:</b> {props.data[props.showing]["Vital"]}
              </p>
              <p>
                <b>History:</b> {props.data[props.showing]["History"]}
              </p>
              <p>
                <b>DecisionMaking:</b>{" "}
                {props.data[props.showing]["DecisionMaking"]}
              </p>
              <p>
                <b>Prevention:</b> {props.data[props.showing]["Prevention"]}
              </p>
              <p>
                <b>Effects:</b> {props.data[props.showing]["Effects"]}
              </p>
              <p>
                <b>Referral:</b> {props.data[props.showing]["Referral"]}
              </p>
              <p>
                <b>FollowUp:</b> {props.data[props.showing]["FollowUp"]}
              </p>
              <p>
                <b>Conduct:</b> {props.data[props.showing]["Conduct"]}
              </p>
            </>
          ) : active === "Pharmacological Expert" ? (
            <>
              <p>
                <b>PainRecognition:</b>{" "}
                {props.data[props.showing]["PainRecognition"]}
              </p>
              <p>
                <b>Findings:</b> {props.data[props.showing]["Findings"]}
              </p>
              <p>
                <b>ProblemCause:</b> {props.data[props.showing]["ProblemCause"]}
              </p>
              <p>
                <b>RationalTreatment:</b>{" "}
                {props.data[props.showing]["RationalTreatment"]}
              </p>
              <p>
                <b>DoseCalculation:</b>{" "}
                {props.data[props.showing]["DoseCalculation"]}
              </p>
              <p>
                <b>RouteOfAdministration:</b>{" "}
                {props.data[props.showing]["RouteOfAdministration"]}
              </p>
              <p>
                <b>OwnerExplanation:</b>{" "}
                {props.data[props.showing]["OwnerExplanation"]}
              </p>
            </>
          ) : active === "Agrovet Content" ? (
            <>
              <p>
                <b>Steroids:</b> {props.data[props.showing]["Steroids"]}
              </p>
              <p>
                <b>EyePreparation:</b>{" "}
                {props.data[props.showing]["EyePreparation"]}
              </p>
              <p>
                <b>Dewormers:</b> {props.data[props.showing]["Dewormers"]}
              </p>
              <p>
                <b>Antiseptic:</b> {props.data[props.showing]["Antiseptic"]}
              </p>
              <p>
                <b>SyntheticPyrethoids:</b>
                {props.data[props.showing]["SyntheticPyrethoids"]}
              </p>
              <p>
                <b>PainRelief:</b> {props.data[props.showing]["PainRelief"]}
              </p>
              <p>
                <b>InjectableAntibiotic:</b>
                {props.data[props.showing]["InjectableAntibiotic"]}
              </p>
              <p>
                <b>OralAntibiotic:</b>
                {props.data[props.showing]["OralAntibiotic"]}
              </p>
              <p>
                <b>WelfareProducts:</b>
                {props.data[props.showing]["WelfareProducts"]}
              </p>
              <p>
                <b>MedicineStorage:</b>{" "}
                {props.data[props.showing]["MedicineStorage"]}
              </p>
              <p>
                <b>EquipmentDisposables:</b>
                {props.data[props.showing]["EquipmentDisposables"]}
              </p>
            </>
          ) : active === "Governance" ? (
            <>
              <p>
                <b>ProductDisplay:</b>
                {props.data[props.showing]["ProductDisplay"]}
              </p>
              <p>
                <b>AppropriateWastes:</b>
                {props.data[props.showing]["AppropriateWastes"]}
              </p>
              <p>
                <b>RecordKeeping:</b>
                {props.data[props.showing]["RecordKeeping"]}
              </p>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

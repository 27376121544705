import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { TextField, CircularProgress } from "@mui/material";

const Competencies = (props) => {
  const [data, setData] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  let tool;

  if (props.title === "AMF") {
    tool = "Agrovets";
  } else if (props.title === "AHMF") {
    tool = "Practitioners";
  } else if (props.title === "FMF") {
    tool = "Farriers";
  }

  // Function to handle fetching data from API
  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `/api/data/${tool?.toLowerCase()}/competency/data/${props.partner}/${
          props.start
        }/${props.end}`
      );

      console.log(response?.data?.data);

      // Ensure data is an array
      if (Array.isArray(response?.data?.data)) {
        setData(response?.data?.data);
      } else {
        setError("Data format is incorrect");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to fetch data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [props.partner, props.start, props.end]);

  // MUI DataGrid columns definition based on title
  const getColumns = () => {
    switch (props.title) {
      case "FMF":
        return [
          { field: "Name", headerName: "Name", flex: 1 },
          { field: "Quarter", headerName: "Quarter", flex: 1 },
          { field: "Date", headerName: "Date", flex: 1 },
          { field: "ALC_Site", headerName: "ALC Site", flex: 1 },
          {
            field: "Legal Compliance",
            headerName: "Legal Compliance",
            flex: 1,
          },
          { field: "Communicator", headerName: "Communicator", flex: 1 },
          { field: "Farriery Expert", headerName: "Farriery Expert", flex: 1 },
          { field: "Farrier Kit", headerName: "Farrier Kit", flex: 1 },
          { field: "Governance", headerName: "Governance", flex: 1 },
        ];
      case "AMF":
        return [
          { field: "Agrovet_Name", headerName: "Agrovet Name", flex: 1 },
          { field: "Reporting_Quarter", headerName: "Quarter", flex: 1 },
          { field: "Assessment_Date", headerName: "Date", flex: 1 },
          {
            field: "Legal Compliance",
            headerName: "Legal Compliance",
            flex: 1,
          },
          { field: "Communicator", headerName: "Communicator", flex: 1 },
          {
            field: "Pharmacological Expert",
            headerName: "Pharmacological Expert",
            flex: 1,
          },
          {
            field: "Content of Agrovet",
            headerName: "Agrovet Content",
            flex: 1,
          },
          { field: "Agrovet Governance", headerName: "Governance", flex: 1 },
        ];
      case "AHMF":
        return [
          { field: "Name", headerName: "Name", flex: 1 },
          { field: "Quarter", headerName: "Quarter", flex: 1 },
          { field: "Date", headerName: "Date", flex: 1 },
          {
            field: "Legal Compliance",
            headerName: "Legal Compliance",
            flex: 1,
          },
          { field: "Communicator", headerName: "Communicator", flex: 1 },
          { field: "Clinical Expert", headerName: "Clinical Expert", flex: 1 },
          { field: "Kit Contents", headerName: "Kit Contents", flex: 1 },
          {
            field: "Clinical Governance",
            headerName: "Clinical Governance",
            flex: 1,
          },
        ];
      default:
        return []; // Return an empty array if no title matches
    }
  };

  const columns = getColumns();

  return (
    <div style={{ height: 600, width: "100%" }}>
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </div>
      )}
      {error && <div style={{ color: "red" }}>{error}</div>}
      <TextField
        label="Filter by Name"
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        variant="outlined"
        style={{ marginBottom: 20 }}
      />
      <DataGrid
        rows={data.filter(
          (row) =>
            row.Name?.toLowerCase().includes(filter.toLowerCase()) ||
            row.Agrovet_Name?.toLowerCase().includes(filter.toLowerCase())
        )}
        columns={columns}
        pagination
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[5, 10, 20]}
        checkboxSelection
        disableSelectionOnClick
        getRowId={(row, index) =>
          `row-${index}-${row.Name || row.Agrovet_Name}`
        }
      />
    </div>
  );
};

export default Competencies;

import { useEffect, useState, useLayoutEffect, useRef } from "react";
import { Box, Typography, IconButton } from "@mui/material";
import { BsImages, BsFiletypeCsv } from "react-icons/bs";
import CustomBarChart from "../Stats/CustomBarChart";
import html2canvas from "html2canvas";

// Define the withCommas function here
const withCommas = (num) => {
  return num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0";
};

export default function VCStats(props) {
  const [charts, setCharts] = useState(null);
  const [showing, setShowing] = useState(true);
  const [aspect, setAspect] = useState(1.5);
  const p1ref = useRef();
  const p2ref = useRef();

  useLayoutEffect(() => {
    const { width, height } = p1ref.current.getBoundingClientRect();
    setAspect(width / height);
  }, []);

  const handleDownloadImage = async (printRef) => {
    const element = printRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL("image/jpg");
    const link = document.createElement("a");

    if (typeof link.download === "string") {
      link.href = data;
      link.download = "image.jpg";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
  };

  const saveData = (data) => {
    if (data.length > 0) {
      let rows = [];
      rows.push(Object.keys(data[0]));
      data.map((item) => {
        rows.push(Object.values(item));
      });
      let csvContent =
        "data:text/csv;charset=utf-8," +
        rows.map((e) => e.join(",")).join("\n");
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "data.csv");
      document.body.appendChild(link);
      link.click();
    }
  };

  useEffect(() => {
    setShowing(false);
    setTimeout(() => {
      setShowing(true);
    }, 1);
  }, [props.showing]);

  useEffect(() => {
    const fetchCharts = async () => {
      let url;
      if (props.title === "Agrovets" || props.title === "AMF") {
        url = `/api/agrovets/monitoring/charts/${props.partner}/${props.start}/${props.end}`;
      } else if (props.title === "Farriers" || props.title === "FMF") {
        url = `/api/farriers/monitoring/charts/${props.partner}/${props.start}/${props.end}`;
      } else if (props.title === "Practitioners" || props.title === "AHMF") {
        url = `/api/practitioners/monitoring/charts/${props.partner}/${props.start}/${props.end}`;
      }

      if (url) {
        try {
          const res = await fetch(url);
          if (res.ok) {
            const data = await res.json();
            setCharts(data);
          }
        } catch (e) {
          console.error(e);
        }
      }
    };
    fetchCharts();
  }, [props.partner, props.start, props.end]);

  let tool;
  if (props.title === "AMF") {
    tool = "Agrovets";
  } else if (props.title === "AHMF") {
    tool = "Practitioners";
  } else if (props.title === "FMF") {
    tool = "Farriers";
  }
  tool = tool?.replace("%20", " ").replace("&%20", " & ");

  return (
    <>
      {showing && (
        <Box
          className="stats"
          sx={{
            padding: 2,
            backgroundColor: "#f9f9f9",
            borderRadius: 2,
            position: "relative",
            zIndex: 1,
            overflow: "hidden",
            height: "100%",
          }}
        >
          <Box className="top-monitoring" display="flex" flexWrap="wrap">
            <TopItem
              title={`Total ${tool} Monitored`}
              amt={charts ? charts.Totals : 0}
              color="#C9EEFF"
            />
            <TopItem
              title="Legal Compliance"
              amt={charts ? charts.LegalComplicance : 0}
              color="#F1DEC9"
              link="/amf"
            />
            <TopItem
              title="Communicator"
              amt={charts ? charts.Communicator : 0}
              color="#FBFFB1"
              link="/communityengagement/Careclubs"
            />
            <TopItem
              title={
                props.title === "AMF"
                  ? "Pharmacological Expert"
                  : props.title === "FMF"
                  ? "Farriery Expert"
                  : "Clinical Expert"
              }
              amt={charts ? charts.Pharmacological : 0}
              color="#FFA3FD"
              link="/communityengagement/Careclubs"
            />
            <TopItem
              title={
                props.title === "AMF"
                  ? "Content of Agrovet"
                  : props.title === "FMF"
                  ? "Farrier Kit"
                  : "Kit Content"
              }
              amt={charts ? charts?.Content : 0}
              color="#C9F4AA"
              link="/communityengagement/Careclubs"
            />
            <TopItem
              title={
                props.title === "AMF"
                  ? "Agrovet Governance"
                  : props.title === "FMF"
                  ? "Farrier Governance"
                  : "Clinical Governance"
              }
              amt={charts ? charts?.Governance : 0}
              color="#FFFBEB"
              link="/communityengagement/Careclubs"
            />
          </Box>
          <Box
            className="piesMonitoring"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            sx={{ marginTop: 3, overflow: "hidden" }}
          >
            <Box
              ref={p1ref}
              className="chart"
              sx={{
                flex: 1,
                marginRight: 1,
                padding: 2,
                backgroundColor: "#fff",
                borderRadius: 2,
                boxShadow: 3,
                display: "flex",
                flexDirection: "column",
                height: "100%",
                overflow: "hidden",
              }}
            >
              <Typography
                variant="h6"
                sx={{ mb: 2, fontWeight: "bold", color: "#333" }}
              >
                Average Competency Performance
              </Typography>
              {charts && (
                <CustomBarChart data={charts?.Competency} aspect={aspect} />
              )}
              <Box
                className="save"
                display="flex"
                alignItems="center"
                sx={{ marginTop: 2 }}
              >
                <IconButton onClick={() => handleDownloadImage(p1ref)}>
                  <BsImages color="blue" />
                </IconButton>
                <IconButton onClick={() => saveData(charts?.Competency)}>
                  <BsFiletypeCsv color="blue" />
                </IconButton>
              </Box>
            </Box>
            <Box
              ref={p2ref}
              className="chart"
              sx={{
                flex: 1,
                marginLeft: 1,
                padding: 2,
                backgroundColor: "#fff",
                borderRadius: 2,
                boxShadow: 3,
                display: "flex",
                flexDirection: "column",
                height: "100%",
                overflow: "hidden",
              }}
            >
              <Typography
                variant="h6"
                sx={{ mb: 2, fontWeight: "bold", color: "#333" }}
              >
                AMF Results
              </Typography>
              {charts && (
                <CustomBarChart data={charts?.Results} aspect={aspect} />
              )}
              <Box
                className="save"
                display="flex"
                alignItems="center"
                sx={{ marginTop: 2 }}
              >
                <IconButton onClick={() => handleDownloadImage(p2ref)}>
                  <BsImages color="blue" />
                </IconButton>
                <IconButton onClick={() => saveData(charts?.Results)}>
                  <BsFiletypeCsv color="blue" />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}

const TopItem = (props) => {
  return (
    <Box
      className="item"
      sx={{
        flex: 1,
        padding: 2,
        backgroundColor: props.color,
        borderRadius: 2,
        boxShadow: 2,
        margin: 1,
        overflow: "hidden", // Prevent overflow
      }}
    >
      <Typography variant="body2" sx={{ color: "#666" }}>
        {props.title}
      </Typography>
      <Typography variant="h5" sx={{ fontWeight: "bold" }}>
        {withCommas(props.amt)}
      </Typography>
    </Box>
  );
};

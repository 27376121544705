import React, { useEffect, useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Typography,
  IconButton,
} from "@mui/material";
import {
  FaBars,
  FaHome,
  FaTasks,
  FaFolder,
  FaHdd,
  FaMap,
  FaNewspaper,
  FaUser,
  FaCog,
} from "react-icons/fa";
import jwt from "jsonwebtoken";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/imgs/logo.png";

export default function Navigation2(props) {
  const navigate = useNavigate();
  const [role, setRole] = useState();
  const [open, setOpen] = useState(true); // State to toggle drawer open/close
  const token = localStorage.getItem("gdfhgfhtkngdfhgfhtkn");

  useEffect(() => {
    if (token) {
      const decoded = jwt.decode(token);
      const partner = decoded?.Role;
      setRole(partner);
    }
  }, [token]);

  const handleNavigation = (url) => {
    if (url === "/logout") {
      localStorage.clear();
      navigate("/login");
    } else {
      navigate(url);
    }
  };

  const toggleDrawer = () => {
    setOpen(!open); // Toggle the drawer open/close state
  };

  return (
    <>
      <IconButton
        onClick={toggleDrawer}
        style={{ position: "absolute", top: 16, left: 16, zIndex: 1200 }}
      >
        <FaBars size={24} />
      </IconButton>

      <Drawer
        sx={{
          width: 250,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: 250,
            backgroundColor: "#ffffff",
            borderRight: "1px solid #425563",
            boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
          },
        }}
        variant="persistent" // Can also use 'temporary' for temporary drawer
        anchor="left"
        open={open}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "16px",
            height: "120px",
            background: "#ffffff",
            color: "#ffffff",
            borderTopLeftRadius: "0px",
            borderTopRightRadius: "0px",
          }}
        >
          <div
            style={{
              backgroundColor: "#ffffff",
              borderRadius: "50%",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              marginRight: 16,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "3px solid #425563",
              width: "80px", // Increased container width
              height: "80px", // Increased container height
            }}
          >
            <img
              src={logo}
              alt="Logo"
              style={{
                width: "70px", // Increased logo width
                height: "70px", // Increased logo height
                borderRadius: "50%", // Ensure logo remains circular
                objectFit: "cover", // Ensures the image covers the container without distortion
              }}
            />
          </div>
          <Typography
            variant="h6"
            style={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              letterSpacing: "0.5px",
              transition: "color 0.3s ease",
              color: "#425563",
            }}
          >
            BEA Data Portal
          </Typography>
        </div>

        <Divider />

        <List sx={{ padding: 0 }}>
          {[
            { text: "Home", icon: <FaHome />, path: "/" },
            { text: "AMF Analysis", icon: <FaTasks />, path: "/AMF" },
            { text: "FMF Analysis", icon: <FaFolder />, path: "/FMF" },
            { text: "AHMF Analysis", icon: <FaHdd />, path: "/AHMF" },
            { text: "GIS Maps", icon: <FaMap />, path: "/gis" },
            { text: "MEL Section", icon: <FaNewspaper />, path: "/indicators" },
          ].map(({ text, icon, path }) => (
            <ListItem
              button
              key={text}
              onClick={() => handleNavigation(path)}
              sx={{
                "&:hover": {
                  backgroundColor: "#ffe0b2",
                  transition: "background-color 0.3s ease",
                },
                padding: "12px 16px",
                borderRadius: "8px",
              }}
            >
              <ListItemIcon>
                {React.cloneElement(icon, { size: 20, color: "#425563" })}
              </ListItemIcon>
              <ListItemText
                primary={text}
                sx={{ fontWeight: "medium", color: "#425563" }}
              />
            </ListItem>
          ))}

          {role === "Admin" && (
            <>
              <Divider sx={{ margin: "8px 0" }} />
              {[
                { text: "BEA Partners", icon: <FaUser />, path: "/partners" },
                { text: "Users Section", icon: <FaUser />, path: "/users" },
              ].map(({ text, icon, path }) => (
                <ListItem
                  button
                  key={text}
                  onClick={() => handleNavigation(path)}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#ffe0b2",
                      transition: "background-color 0.3s ease",
                    },
                    padding: "12px 16px",
                    borderRadius: "8px",
                  }}
                >
                  <ListItemIcon>
                    {React.cloneElement(icon, { size: 20, color: "#425563" })}
                  </ListItemIcon>
                  <ListItemText
                    primary={text}
                    sx={{ fontWeight: "medium", color: "#425563" }}
                  />
                </ListItem>
              ))}
            </>
          )}

          <ListItem
            button
            onClick={() => handleNavigation("/settings")}
            sx={{
              "&:hover": {
                backgroundColor: "#ffe0b2",
                transition: "background-color 0.3s ease",
              },
              padding: "12px 16px",
              borderRadius: "8px",
            }}
          >
            <ListItemIcon>
              <FaCog size={20} color="#425563" />
            </ListItemIcon>
            <ListItemText
              primary="Account Details"
              sx={{ fontWeight: "medium", color: "#425563" }}
            />
          </ListItem>
        </List>
      </Drawer>
    </>
  );
}

import React, { useEffect, useState } from "react";
import jwt from "jsonwebtoken"; // Moved import to top
import { AppBar, Toolbar, IconButton, Typography, Button } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

export default function Header(props) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentUser, setCurrentUser] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("gdfhgfhtkngdfhgfhtkn");

    if (token) {
      try {
        const decoded = jwt.decode(token);
        setCurrentUser(decoded);

        if (Date.now() >= decoded.exp * 1000) {
          setIsAuthenticated(false);
        } else {
          setIsAuthenticated(true);
        }
      } catch (error) {
        setIsAuthenticated(false);
      }
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  const logout = () => {
    fetch("/api/auth/logout", {
      method: "get",
      credentials: "include",
    })
      .then((res) => res.json())
      .then(() => {
        setIsAuthenticated(false);
        localStorage.clear();
        window.location.href = "/login";
      })
      .catch(() => {
        setIsAuthenticated(false);
        localStorage.clear();
        window.location.href = "/login";
      });
  };

  return (
    <AppBar position="static" sx={{ bgcolor: "#fe5000" }}>
      {" "}
      {/* Brooke Orange */}
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={() => props.setShowing(!props.showing)}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          {currentUser.Name || "Welcome"}
        </Typography>
        <Button color="inherit" startIcon={<ExitToAppIcon />} onClick={logout}>
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
}

import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { TextField } from "@mui/material";

// Custom Toolbar for the DataGrid, if needed
const CustomToolbar = () => {
  return (
    <div style={{ padding: "10px" }}>
      {/* Add any custom toolbar buttons or inputs here */}
    </div>
  );
};

const PartnerResultsTable = (props) => {
  const [data, setData] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [filter, setFilter] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  let tool;

  // Determine the tool based on the title prop
  if (props.title === "AMF") {
    tool = "Agrovets";
  } else if (props.title === "AHMF") {
    tool = "Practitioners";
  } else if (props.title === "FMF") {
    tool = "Farriers";
  }

  // Function to handle fetching data from API
  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `/api/data/${tool?.toLowerCase()}/results/data/${props.partner}/${
          props.start
        }/${props.end}`
      );

      console.log(response?.data?.data);

      // Ensure data is an array
      if (Array.isArray(response?.data?.data)) {
        setData(response.data.data);
      } else {
        setError("Data format is incorrect");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to fetch data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [props.partner, props.start, props.end]);

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
  };

  // MUI DataGrid columns definition based on title
  const getColumns = () => {
    switch (props.title) {
      case "AMF":
        return [
          { field: "Agrovet_Name", headerName: "Name", flex: 1 },
          { field: "Location", headerName: "Location", flex: 1 },
          { field: "Reporting_Quarter", headerName: "Quarter", flex: 1 },
          { field: "Assessment_Date", headerName: "Date", flex: 1 },
          { field: "Score", headerName: "Score", flex: 1 },
          { field: "Percentage", headerName: "Percentage", flex: 1 },
        ];
      case "FMF":
        return [
          { field: "Name", headerName: "Name", flex: 1 },
          { field: "ALC_Site", headerName: "ALC Site", flex: 1 },
          { field: "Quarter", headerName: "Quarter", flex: 1 },
          { field: "Date", headerName: "Date", flex: 1 },
          { field: "Score", headerName: "Score", flex: 1 },
          { field: "Percentage", headerName: "Percentage", flex: 1 },
        ];
      case "AHMF":
        return [
          { field: "Name", headerName: "Name", flex: 1 },
          { field: "Case_Summary", headerName: "Case Summary", flex: 1 },
          { field: "Quarter", headerName: "Quarter", flex: 1 },
          { field: "Date", headerName: "Date", flex: 1 },
          { field: "Score", headerName: "Score", flex: 1 },
          { field: "Percentage", headerName: "Percentage", flex: 1 },
        ];
      default:
        return []; // Return an empty array if no title matches
    }
  };

  const columns = getColumns();

  return (
    <div style={{ height: 600, width: "100%" }}>
      {loading && <div>Loading...</div>}
      {error && <div style={{ color: "red" }}>{error}</div>}
      <TextField
        label="Filter by Name"
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        variant="outlined"
        style={{ marginBottom: 20 }}
      />
      <DataGrid
        rows={data}
        columns={columns}
        pagination
        pageSize={pageSize}
        onPageSizeChange={handlePageSizeChange}
        rowsPerPageOptions={[5, 10, 20]}
        checkboxSelection
        disableSelectionOnClick
        components={{
          Toolbar: CustomToolbar,
        }}
        filterModel={{
          items: [
            {
              columnField: "Name",
              operatorValue: "contains",
              value: filter,
            },
          ],
        }}
        // Using a combination of index and a unique string to generate unique IDs
        getRowId={(row, index) => `row-${index}-${row.Name}`}
      />
    </div>
  );
};

export default PartnerResultsTable;

import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Container,
  Paper,
} from "@mui/material";
import DiagonalRectangle from "../components/Util/DiagonalRectangle";
import agrovethome from "../assets/imgs/agrovetshome.jpg";
import practitionershome from "../assets/imgs/Practititionerhome.jpg";
import equinehome from "../assets/imgs/equinehome.jpg";
import communityhome from "../assets/imgs/communityhome.jpg";
import schoolshome from "../assets/imgs/schoolshome.jpg";
import farrierhome from "../assets/imgs/lg8.jpg";
import Footer2 from "../components/Util/Footer2";

export default function LandingNew() {
  const [stats, setStats] = useState(null);

  useEffect(() => {
    fetch(`/api/data/all/stats`)
      .then((res) => {
        if (res.ok) return res.json();
        else throw Error("");
      })
      .then((data) => {
        setStats(data);
      })
      .catch((e) => {});
  }, []);

  const navigateToPage = (url) => {
    window.location.href = url;
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 2, mb: 2 }}>
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12} md={8}>
          <Typography
            variant="h4"
            align="center"
            gutterBottom
            sx={{ color: "#425563" }}
          >
            Welcome to Our Platform
          </Typography>
          <Typography
            variant="body1"
            align="center"
            sx={{ mb: 3, color: "#425563" }}
          >
            Explore our various frameworks aimed at enhancing animal health
            services and professional standards within the agrovet industry.
          </Typography>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper
            elevation={3}
            sx={{ padding: 2, borderRadius: 2, backgroundColor: "#ffffff" }}
          >
            <Typography
              variant="h6"
              align="center"
              gutterBottom
              sx={{ color: "#425563" }}
            >
              Quick Links
            </Typography>
            <Button
              variant="contained"
              sx={{
                mb: 1,
                backgroundColor: "#425563", // Brooke Grey
                color: "#ffffff",
                "&:hover": {
                  backgroundColor: "#6d7f8c", // Lighter grey on hover
                },
              }}
              fullWidth
              onClick={() => navigateToPage("/partners")}
            >
              Partners
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#425563", // Brooke Grey
                color: "#ffffff",
                "&:hover": {
                  backgroundColor: "#6d7f8c", // Lighter grey on hover
                },
              }}
              fullWidth
              onClick={() => navigateToPage("/gis")}
            >
              Map
            </Button>
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        {[
          {
            title: "Agrovets Monitoring Framework",
            description:
              "Focuses on building skills, addressing gaps, and strengthening inter-professional relationships.",
            handleClick: () => navigateToPage("/AMF"),
          },
          {
            title: "Farriers Monitoring Framework",
            description:
              "Aims to raise the profile of farriery as a trade and improve hoof health for animals.",
            handleClick: () => navigateToPage("/FMF"),
          },
          {
            title: "Animal Health Framework",
            description:
              "Essential skills and competencies required for good quality animal health services.",
            handleClick: () => navigateToPage("/AHMF"),
          },
        ].map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              variant="outlined"
              sx={{ borderRadius: 2, boxShadow: 2, backgroundColor: "#f5f5f5" }}
            >
              <CardContent>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold", color: "#425563" }}
                >
                  {item.title}
                </Typography>
                <Typography variant="body2" sx={{ mb: 2, color: "#425563" }}>
                  {item.description}
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#425563", // Brooke Grey
                    color: "#ffffff",
                    "&:hover": {
                      backgroundColor: "#6d7f8c", // Lighter grey on hover
                    },
                  }}
                  onClick={item.handleClick}
                  fullWidth
                >
                  Learn More
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={2} sx={{ mb: 3, mt: 4 }}>
        {[
          {
            title: "Practitioners",
            link: "/animalhealth/Practitioners",
            amt: stats ? stats.Practitioners : 0,
            imageSrc: practitionershome,
          },
          {
            title: "Farriers",
            link: "/animalhealth/Farriers",
            amt: stats ? stats.Farriers : 0,
            imageSrc: communityhome,
          },
          {
            title: "Equine Owners",
            link: "/communityengagement/Equineowners",
            amt: stats ? stats.Equineowners : 0,
            imageSrc: equinehome,
          },
          {
            title: "Community Groups",
            link: "/communityengagement/Communitygroups",
            amt: stats ? stats.Communitygroups : 0,
            imageSrc: farrierhome,
          },
          {
            title: "Care Clubs",
            link: "/communityengagement/Careclubs",
            amt: stats ? stats.Careclubs : 0,
            imageSrc: schoolshome,
          },
          {
            title: "Agrovets",
            amt: stats ? stats.Agrovets : 0,
            link: "/animalhealth/Agrovets",
            imageSrc: agrovethome,
          },
        ].map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <TopItem {...item} />
          </Grid>
        ))}
      </Grid>

      <Footer2 />
    </Container>
  );
}

const TopItem = ({ title, link, amt, imageSrc }) => {
  function withCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        borderRadius: 2,
        boxShadow: 3,
        cursor: "pointer",
        overflow: "hidden",
        height: "100%",
        "&:hover": {
          boxShadow: 5,
        },
        backgroundColor: "#f5f5f5", // Light grey for the card background
      }}
      onClick={() => {
        window.location.href = link;
      }}
    >
      <Box
        component="img"
        src={imageSrc}
        alt={title}
        sx={{
          width: "100%",
          height: 140,
          objectFit: "cover",
          borderRadius: "2px 2px 0 0",
        }}
      />
      <Typography
        variant="h6"
        sx={{ fontWeight: "bold", color: "#425563", mt: 1 }} // Grey for text
      >
        {title}
      </Typography>
      <Typography variant="h5" sx={{ color: "#425563" }}>
        {" "}
        {/* Changed to Grey */}
        {withCommas(amt)}
      </Typography>
    </Card>
  );
};

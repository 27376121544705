import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.scss";
import NotFound from "./Pages/404";
import Login from "./Pages/Login2";
import Home from "./Pages/Home";
import LandingNew from "./Pages/LandingNew";
import ProtectedRoute from "./components/Login/ProtectedRoute";
import AMFSections from "./components/Monitoring/Agrovets/LegalCompliance/AMFSection";
import Framework from "./components/Frameworks/Framework";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<ProtectedRoute component={Home} />} />

        <Route path="/gis/*" element={<ProtectedRoute component={Home} />} />
        <Route
          path="/animalhealth/*"
          element={<ProtectedRoute component={Home} />}
        />
        <Route
          path="/communityengagement/*"
          element={<ProtectedRoute component={Home} />}
        />
        <Route
          path="/monitoring/*"
          element={<ProtectedRoute component={Home} />}
        />

        <Route path="/AMF/*" element={<ProtectedRoute component={Home} />} />

        <Route path="/FMF/*" element={<ProtectedRoute component={Home} />} />
        <Route path="/AHMF/*" element={<ProtectedRoute component={Home} />} />

        <Route
          path="/partners/*"
          element={<ProtectedRoute component={Home} />}
        />

        <Route
          path="/indicators/*"
          element={<ProtectedRoute component={Home} />}
        />

        <Route path="/users/*" element={<ProtectedRoute component={Home} />} />

        <Route path="/mobile/*" element={<ProtectedRoute component={Home} />} />

        <Route path="/settings" element={<ProtectedRoute component={Home} />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
